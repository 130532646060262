import { BaseElement } from '../BaseElement';
import { setCookie } from '../util/Cookies';

class TrackingElement extends BaseElement {
    constructor() {
        super();

        this.trackingLinksInRichText = [];
        this.trackingElements = {};

        this.richTexts = [];
    }

    hooks() {
        return {
            connected: () => {
                this.registerLinksInRichTexts();
            },
        };
    }

    events() {
        return {
            '[data-tracking-external-link]': {
                click: ({ target }) => {
                    if (window._etracker) {
                        window._etracker.sendEvent(
                            new et_LinkEvent(
                                target.getAttribute('data-tracking-object'),
                                target.getAttribute('data-tracking-type'),
                            ),
                        );
                    }
                },
            },

            '[data-tracking-authentication-login]': {
                click: e => {
                    // should be used on click at sso login button:
                    // window._etracker.sendEvent(new et_AuthenticationSuccessEvent('Login erfolgreich', ''));
                    // or
                    // window._etracker.sendEvent(new et_AuthenticationFailureEvent('Login nicht erfolgreich', ''));
                    if (window._etracker) {
                        window._etracker.sendEvent(new et_AuthenticationSuccessEvent('Loginversuch', ''));
                    }
                    setCookie('prevUrl', e.currentTarget.getAttribute('prevUrl'), 1);
                },
            },

            '[data-tracking-authentication-logout]': {
                click: ({ target }) => {
                    if (window._etracker) {
                        window._etracker.sendEvent(new et_AuthenticationLogoutEvent('Logout', ''));
                    }
                },
            },

            '[data-tracking-hover]': {
                mouseenter: ({ target }) => {
                    if (window._etracker) {
                        window._etracker.sendEvent(new et_ClickEvent('Shortcut', '%C3%B6ffnen'));
                    }
                },
                mouseleave: ({ target }) => {
                    if (window._etracker) {
                        window._etracker.sendEvent(new et_ClickEvent('Shortcut', 'schlie%E1%BA%9Een'));
                    }
                },
            },

            '[data-tracking-click]': {
                click: ({ target }) => {
                    if (window._etracker) {
                        window._etracker.sendEvent(
                            new et_ClickEvent(
                                target.getAttribute('data-tracking-object'),
                                target.getAttribute('data-tracking-type'),
                            ),
                        );
                    }
                },
            },

            '[data-tracking-download]': {
                click: ({ target }) => {
                    if (window._etracker) {
                        window._etracker.sendEvent(
                            new et_DownloadEvent(
                                target.getAttribute('data-tracking-object'),
                                target.getAttribute('data-tracking-type'),
                            ),
                        );
                    }
                },
            },
        };
    }

    registerLinksInRichTexts() {
        const richTextElements = document.getElementsByClassName('rich-text');
        const richTextsArr = Array.from(richTextElements);
        let linkNodeList = richTextsArr.map(richText => {
            return Array.from(richText.querySelectorAll('a'));
        });

        //rewrite flat() to function to fix IE11 errors
        let flatArray = linkNodeList.reduce((acc, val) => acc.concat(val), []);

        flatArray.forEach(linkNode => {
            if (linkNode.getAttribute('href').includes('dam')) {
                linkNode.setAttribute('data-tracking-download', '');
                linkNode.setAttribute('data-tracking-object', linkNode.text);
            } else if (linkNode.getAttribute('href').startsWith('/')) {
                // what should be done with the internal links?
                linkNode.setAttribute('data-tracking-click', '');
                linkNode.setAttribute('data-tracking-object', linkNode.text);
                linkNode.setAttribute('data-tracking-type', linkNode.href);
            } else {
                linkNode.setAttribute('data-tracking-external-link', '');
                linkNode.setAttribute('data-tracking-object', linkNode.href);
            }
        });
        this.registerEvents();
    }
}

customElements.define('tracking-element', TrackingElement);
